//export const apiUrl = "http://localhost:8080";

// export const apiUrl = "http://192.168.1.152:8080";
// export const apiUrlTest = "http://192.168.100.139:8081";
export const apiUrl = "https://cwvl-api-dkkj4.ondigitalocean.app";

export const chatCRMUrl = "https://chat-crm-34buy.ondigitalocean.app";
export const notificationCRM =
    "https://notification-service-skqn9.ondigitalocean.app";
// export const paymentsApiUrl = "http://localhost:8081";
export const paymentsApiUrl = "https://coral-app-74abl.ondigitalocean.app";
// export const chatCRMUrl = "http://192.168.100.139:8081";
// export const notificationCRM = "http://192.168.100.139:8080";
// export const apiUrl = "https://staging.cwvl-bot.link";
// export const apiUrl = "https://api.cwvl-bot.link";
// export const apiUrl = "https://3525-2806-103e-1-4a1a-55a3-d2c3-9342-1b1.ngrok.io";

export const namespace_1 = "e9a9ebd6_6ae8_461f_8586_3f409d472e41";

export const namespace_2 = "cde31b48_79aa_40f4_a8c0_b6ab37cf68f6";
